import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { objectiveGetAll } from '../../services/ApiRequestService';
import { TObjective } from '../../types/TObjective';

const Objectives = () => {

    const [objectives, setObjectives] = useState<TObjective[]>([]);

    React.useEffect(() => {

        objectiveGetAll().then(
            (objectives) => {
                setObjectives(objectives)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    return (
        <div>
            <h1>List of tasks</h1>
            {objectives.map((objective, i) => {
                return (
                    <React.Fragment>

                        <p>{objective.name}
                            <Link to={'/objective/' + objective.id}>
                                <Button type='button'>show</Button>
                            </Link>
                            <Link to={'/objective/edit/' + objective.id}>
                                <Button type='button'>edit</Button>
                            </Link>
                        </p>
                    </React.Fragment>)
            })}
            
            <Link to='/objective/edit/new'>
                <Button type='button'>new objective</Button>
            </Link>
        </div>
    );
};

export default Objectives