import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { userGetAll } from '../../services/ApiRequestService';
import { TUser } from '../../types/TUser';

const Users = () => {

    const [users, setUsers] = useState<TUser[]>([]);

    React.useEffect(() => {

        userGetAll().then(
            (users) => {
                setUsers(users)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);
        
    


    return (
        <div>
            <h1>List of users</h1>
            {users.map((user, i) => {
                return (
                    <React.Fragment>
                        <p>{user.email}
                            <Link to={'/settings/user/' + user.id}>
                                <Button type='button'>show</Button>
                            </Link>
                            <Link to={'/settings/user/edit/' + user.id}>
                                <Button type='button'>edit</Button>
                            </Link>
                        </p>
                    </React.Fragment>)
            })}
            
            <Link to='/settings/user/edit/new'>
                <Button type='button'>new user</Button>
            </Link>
        </div>
    );
};

export default Users