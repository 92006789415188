import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { categoryGetAll } from '../../services/ApiRequestService';
import { TCategory } from '../../types/TCategory';

const Categories = () => {

    const [categories, setCategories] = useState<TCategory[]>([]);

    React.useEffect(() => {

        categoryGetAll().then(
            (categories) => {
                setCategories(categories)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);
        
    


    return (
        <div>
            <h1>List of categories</h1>
            {categories.map((category, i) => {
                return (
                    <React.Fragment>
                        <p>{category.name}
                            <Link to={'/settings/category/' + category.id}>
                                <Button type='button'>show</Button>
                            </Link>
                            <Link to={'/settings/category/edit/' + category.id}>
                                <Button type='button'>edit</Button>
                            </Link>
                        </p>
                    </React.Fragment>)
            })}
            
            <Link to='/settings/category/edit/new'>
                <Button type='button'>new category</Button>
            </Link>
        </div>
    );
};

export default Categories