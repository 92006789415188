import * as React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { getAccessLevel } from '../../services/AccessService';
import { TAccessLevel } from '../../types/TAccessLevel';
import BaseInternalPage from '../BaseInternalPage';
import PersonalSettings from './PersonalSettings';
import PersonalSettingsEdit from './PersonalSettingsEdit';
import User from './User';
import UserEdit from './UserEdit';
import Users from './Users';
export const SettingsPage = () => {
    var accessLeves = getAccessLevel();

    return (
        <BaseInternalPage>
            <h1>Settings</h1>
            <p>TODO: Test no access</p>
            <div>
                <ul>
                    {/*{accessLeves.includes(TAccessLevel.ADMIN) ? <li><Link to='admin'>AdminSettings</Link></li> : null}*/}

                    <li><Link to=''>Personal settings</Link></li>
                    <li><Link to='site'>Site settings</Link></li>
                    <li><Link to='users'>List of users</Link></li>
                </ul>
            </div>
            <div>
                <Routes>
                    {/*{accessLeves.includes(TAccessLevel.ADMIN) ? <Route path="admin/*" element={<AdminSettings />} /> : null}*/}

                    <Route path="/" element={<PersonalSettings />} />
                    <Route path="/edit" element={<PersonalSettingsEdit />} />
                    <Route path="users" element={<Users />} />
                    <Route path="user/:id" element={<User />} />
                    <Route path="user/edit/:id" element={<UserEdit />} />
                </Routes>
            </div>
        </BaseInternalPage>
    );
};

export default SettingsPage
