import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { sendInviteLink, userGet } from '../../services/ApiRequestService';
import { TSendInviteLinkRequest } from '../../types/TSendInviteLinkRequest';
import { TUser } from '../../types/TUser';

const User = () => {
    let { id } = useParams();

    if(id === undefined){
        return (<p>No user</p>);
    }

    const [user, setUser] = useState<TUser>({} as TUser);

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        userGet(id).then((user) => {
            if (user !== null) {
                setUser(user);
            }
        });
        return () => {
            //alert('Will unmount');
        };
    }, []);

    const sendInvite = async () => {

        //TODO: probably find better solution, I dont like empty password
        const sendInviteLinkRequest: TSendInviteLinkRequest = {
            email: user.email
        };

        await sendInviteLink(sendInviteLinkRequest);

        alert("Odesláno");
    }

    return (
        <div>
            <h1>User</h1>
            <p>Email: { user.email }</p>
            <p>First name: {user.firstName}</p>
            <p>Last name: {user.lastName}</p>
            <p>Roles: {
                (user.roles !== undefined) ? user.roles.map((role, i) => { return (role.name + ' ') }) : ''
            }
            </p>
            <Link to={'/settings/user/edit/' + id}><Button type='button'>Edit</Button></Link>
            <Link to='/settings/users'><Button type='button'>Back</Button></Link>
            <Button type='button' onClick={sendInvite}>Send invite</Button>

        </div>
    );
};

export default User