import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { roleGetAll, userAdd, userDelete, userGet, userUpdate } from '../../services/ApiRequestService';
import { TRole } from '../../types/TRole';
import { TUser } from '../../types/TUser';

const UserEdit = () => {

    let { id } = useParams();

    if(id === undefined){
        return <p>No user</p>;
    }

    const [user, setUser] = useState<TUser>({} as TUser);
    const [roles, setRoles] = useState<TRole[]>([]);

    const [selectedRoles, setSelectedRoles] = useState<number[]>([]);

    //TODO: roles checkboxes doesnt work without it. Find better solution or fix it
    const [loaded, setLoaded] = useState<boolean>(false);

    const navigate = useNavigate();

    
    useEffect(() => {
        if (id === undefined) {
            return;
        }

        if (id !== 'new') {
            userGet(id).then((user) => {
                if (user !== null) {
                    setUser(user);
                    setSelectedRoles(user.roleIds);
                    setLoaded(true);
                }
            });
        }
        else {
            setLoaded(true);
        }

        roleGetAll().then(
            (roles) => {
                setRoles(roles);
            }
        );
                
        

        return () => {
            //alert('Will unmount');
        };
    }, []);

    const Save = async () => {

        user.roleIds = selectedRoles;
        setUser(user);

        if (id === 'new') {
            //insert
            if (id !== undefined) {
                userAdd(user).then((user) => {
                    if (user !== null) {
                        navigate("/settings/user/" + user.id);
                    }
                    else {
                        navigate("/settings/users");
                    }
                });
            }
        }
        else {
            //update
            if (id !== undefined) {
                userUpdate(user).then(() => {
                    navigate("/settings/user/" + id);
                });
            }
        }
    }

    const Delete = async () => {

        if (confirm('Do you want to delete this user?')) {
            if (id !== undefined) {
                userDelete(id).then(() => {
                    navigate("/settings/admin/users");
                });
            }
        }
    }

    const checkBoxChanged = (id: number, checked: boolean) => {
        const index = selectedRoles.indexOf(id);
        if (checked) {
            if (index == -1) {
                selectedRoles.push(id);
            }
        }
        else {
            if (index > -1) {
                selectedRoles.splice(index, 1);
            }
        }
        setSelectedRoles(selectedRoles);
    }; 

    return (
        <div>
            <h1>User</h1>

            <p>Email: <input type="text" value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} /></p>
            <p>First name: <input name="firstName" type="text" value={user.firstName} onChange={(e) => setUser({ ...user, firstName: e.target.value })} /></p>
            <p>Last name: <input name="lastName" type="text" value={user.lastName} onChange={(e) => setUser({ ...user, lastName: e.target.value })} /></p>

            <p>Add role edit</p>
            {roles.map((role, i) => {

                if (!loaded)
                    return;

                return (
                    <div key={role.id}>
                        <p><input id={role.id.toString()} defaultChecked={selectedRoles.includes(role.id)} onChange={(e) => checkBoxChanged(role.id, e.target.checked)}
                            type='checkbox'></input>{role.name}</p>
                    </div>)
            })}

            <Button type='button' onClick={Save}>Save</Button>
            <Button type='button' onClick={Delete}>Delete</Button>
            <Link to='/settings/users'><Button type='button'>Back</Button></Link>

        </div>
    );
};

export default UserEdit