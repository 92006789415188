import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { signUp } from '../../services/ApiRequestService';
import { TCredentials } from '../../types/TCredentials';

const SignUpPage = () => {
    const [credentials, setCredentials] = useState<TCredentials>({} as TCredentials);
    const [userPassword2, setUserPassword2] = useState<string>('');

    const SignUp = async () => {
        if (credentials.password !== userPassword2) {
            alert('heslo nen� stejn�');
            return;
        }

        await signUp(credentials);

        alert('Odesl�no');
    }

    return <div>
        <h1>Registrace</h1>
        <p>Email: <input type="text" value={credentials.email} onChange={(e) => setCredentials({ ...credentials, email: e.target.value })} /></p>
        <p>Heslo: <input type="password" value={credentials.password} onChange={(e) => setCredentials({ ...credentials, password: e.target.value })} /></p>
        <p>Heslo znovu: <input type="password" value={userPassword2} onChange={e => setUserPassword2(e.target.value)} /></p>
        <p>TODO: add captcha</p>
        <Button type='button' onClick={SignUp}>Registrovat</Button>
    </div>
}

export default SignUpPage
