import { TAuthToken } from '../types/TAuthToken';

const USER_AUTH_TOKEN_KEY = 'userAuthToken';

export function setAuthToken(authToken: TAuthToken) {
    if (authToken.authToken !== undefined) {
        localStorage.setItem(USER_AUTH_TOKEN_KEY, authToken.authToken);
    }
}

export function getAuthToken(): TAuthToken | undefined {
    var authToken = localStorage.getItem(USER_AUTH_TOKEN_KEY)
    if (authToken !== null) {

        const requestBody: TAuthToken = {
            authToken: authToken,
            successful: false
        };

        return requestBody;
    }
}

export function removeAuthToken() {
    localStorage.removeItem(USER_AUTH_TOKEN_KEY);
}