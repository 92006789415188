import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { submitNewPassword } from '../../services/ApiRequestService';
import { TSubmitNewPasswordRequest } from '../../types/TSubmitNewPasswordRequest';

const PasswordChangePage = () => {
    const [submitNewPasswordRequest, setSubmitNewPasswordRequest] = useState<TSubmitNewPasswordRequest>({} as TSubmitNewPasswordRequest);
    const [userPassword2, setUserPassword2] = useState<string>('');

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {


        return () => {
            //alert('Will unmount');
        };
    }, []);

    const save = async () => {
        if (submitNewPasswordRequest.password !== userPassword2) {
            alert('heslo není stejné');
            return;
        }

        const token = searchParams.get("token")
        if (token === null)
            return;

        submitNewPasswordRequest.token = token;

        await submitNewPassword(submitNewPasswordRequest);

        alert('Odesláno');
    }

    return (<div>
        <h1>Nastavení hesla</h1>
        <p>Heslo: <input type="password" value={submitNewPasswordRequest.password} onChange={(e) => setSubmitNewPasswordRequest({ ...submitNewPasswordRequest, password: e.target.value })} /></p>
        <p>Heslo znovu: <input type="password" value={userPassword2} onChange={e => setUserPassword2(e.target.value)} /></p>

        <Button type='button' onClick={save}>Uložit</Button>
    </div>)
};

export default PasswordChangePage;
