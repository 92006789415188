import * as React from 'react';
import { Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
//import InnerSiteTest from './pages/InnerSiteTest';
//import SignIn from './pages/SignIn';
//import ForgotPassword from './pages/ForgotPassword';

import './custom.css'
import './base.css'
//import SignUp from './pages/SignUp';
//import AdminSettings from './pages/admin/AdminSettings';
//import LectorSettings from './pages/lector/LectorSettings';
//import StudentSettings from './pages/student/StudentSettings';

export default () => (
    <Layout>
        {/*<Routes>*/}
            {/*<Route path='/' element={<Home />}/>*/}
            {/*<Route path='/it' element={InnerSiteTest} />*/}
            {/*<Route path='/admin' element={AdminSettings} />*/}
            {/*<Route path='/lector' element={LectorSettings} />*/}
            {/*<Route path='/student' element={StudentSettings} />*/}
            {/*<Route path='/signin' element={SignIn} />*/}
            {/*<Route path='/signup' element={SignUp} />*/}
            {/*<Route path='/forgotpassword' element={ForgotPassword} />*/}
        {/*</Routes>*/}
    </Layout>
);
