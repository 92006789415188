import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { userGetCurent } from '../../services/ApiRequestService';
import { TUser } from '../../types/TUser';
//import { Link } from 'react-router-dom';

export const PersonalSettings = () => {

    const [user, setUser] = useState<TUser>({} as TUser);

    useEffect(() => {

        userGetCurent().then((user) => {
            if (user !== null) {
                setUser(user);
            }
        });
        return () => {
            //alert('Will unmount');
        };
    }, []);

    return (
        <div>
            <h1>Osobní nastavení</h1>
            <p>Email: {user.email}</p>
            <p>Jméno: {user.firstName}</p>
            <p>Příjmení: {user.lastName}</p>

            <Link to={'edit'}><Button type='button'>Editovat</Button></Link>
        </div>
    );
};

export default PersonalSettings
