import { TCredentials } from '../types/TCredentials';
import { TAuthToken } from '../types/TAuthToken';
import { getAuthToken } from '../services/LocalStorageService';
import { TUser } from '../types/TUser';
import { TRole } from '../types/TRole';
import { TSubmitNewPasswordRequest } from '../types/TSubmitNewPasswordRequest';
import { TSendInviteLinkRequest } from '../types/TSendInviteLinkRequest';
import { TSendResetPasswordLinkRequest } from '../types/TSendResetPasswordLinkRequest';
import { TCategory } from '../types/TCategory';
import { TPriority } from '../types/TPriority';
import { TObjective } from '../types/TObjective';


//role
export async function roleGetAll(): Promise<TRole[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TRole[]>('api/role/getall', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const roles: TRole[] = [];
    return roles;
}

//user
export async function userGetAll(): Promise<TUser[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TUser[]>('api/user/getall', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const users: TUser[] = [];
    return users;
}

export async function userGet(id: string): Promise<TUser | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TUser>('api/user/get/' + id, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function userGetCurent(): Promise<TUser | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TUser>('api/user/getcurent', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function userAdd(user: TUser): Promise<TUser | null> {
    var body = JSON.stringify(user);
    var data = await apiAuthRequest<TUser>('api/user/add/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function userUpdate(user: TUser): Promise<TUser | null> {
    var body = JSON.stringify(user);
    var data = await apiAuthRequest<TUser>('api/user/update', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function userDelete(id: string) {
    var req = {
        userId: id
    }

    var body = JSON.stringify(req);
    await apiAuthRequest('api/user/delete', body);
}

//category
export async function categoryGetAll(): Promise<TCategory[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TCategory[]>('api/category/getall', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const categories: TCategory[] = [];
    return categories;
}

export async function categoryGet(id: number): Promise<TCategory | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TCategory>('api/category/get/' + id, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function categoryAdd(category: TCategory): Promise<TCategory | null> {
    var body = JSON.stringify(category);
    var data = await apiAuthRequest<TCategory>('api/category/add/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function categoryUpdate(category: TCategory): Promise<TCategory | null> {
    var body = JSON.stringify(category);
    var data = await apiAuthRequest<TCategory>('api/category/update', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function categoryDelete(id: number) {
    var req = {
        categoryId: id
    }

    var body = JSON.stringify(req);
    await apiAuthRequest('api/category/delete', body);
}

//priority
export async function priorityGetAll(): Promise<TPriority[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TPriority[]>('api/priority/getall', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const priorities: TPriority[] = [];
    return priorities;
}

export async function priorityGet(id: number): Promise<TPriority | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TPriority>('api/priority/get/' + id, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function priorityAdd(priority: TPriority): Promise<TPriority | null> {
    var body = JSON.stringify(priority);
    var data = await apiAuthRequest<TPriority>('api/priority/add/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function priorityUpdate(priority: TPriority): Promise<TPriority | null> {
    var body = JSON.stringify(priority);
    var data = await apiAuthRequest<TPriority>('api/priority/update', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function priorityDelete(id: number) {
    var req = {
        priorityId: id
    }

    var body = JSON.stringify(req);
    await apiAuthRequest('api/priority/delete', body);
}

//objective
export async function objectiveGetAll(): Promise<TObjective[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TObjective[]>('api/objective/getall', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const priorities: TObjective[] = [];
    return priorities;
}

export async function objectiveGet(id: number): Promise<TObjective | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TObjective>('api/objective/get/' + id, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function objectiveAdd(objective: TObjective): Promise<TObjective | null> {
    var body = JSON.stringify(objective);
    var data = await apiAuthRequest<TObjective>('api/objective/add/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function objectiveUpdate(objective: TObjective): Promise<TObjective | null> {
    var body = JSON.stringify(objective);
    var data = await apiAuthRequest<TObjective>('api/objective/update', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function objectiveDelete(id: number) {
    var req = {
        objectiveId: id
    }

    var body = JSON.stringify(req);
    await apiAuthRequest('api/objective/delete', body);
}

//authentication
export async function signUp(credentials: TCredentials) {
    var body = JSON.stringify(credentials);
    await apiRequest('api/authentication/signup', body);
}

export async function signIn(credentials: TCredentials): Promise<TAuthToken> {
    var body = JSON.stringify(credentials);
    var data = await apiRequest<TAuthToken>('api/authentication/signin', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const authToken: TAuthToken = {
        successful: false,
        authToken: undefined
    };
    return authToken;
}

export async function sendResetPasswordLink(sendResetPasswordLinkRequest: TSendResetPasswordLinkRequest) {
    var body = JSON.stringify(sendResetPasswordLinkRequest);
    await apiRequest('api/authentication/SendResetPasswordLink', body);
}

export async function sendInviteLink(sendInviteLinkRequest: TSendInviteLinkRequest) {
    var body = JSON.stringify(sendInviteLinkRequest);
    await apiAuthRequest('api/authentication/SendInviteLink', body);
}

export async function submitNewPassword(submitNewPasswordRequest: TSubmitNewPasswordRequest) {
    var body = JSON.stringify(submitNewPasswordRequest);
    await apiRequest('api/authentication/SubmitNewPassword', body);
}

async function apiRequest<T>(url: string, body: string) {
    
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: body
    });

    if (response.status == 200) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {

            const json = await response.json();
            return json as T;
        }

        return;
    }
    else {
        const text = await response.text();
        alert(text);
    }
}

async function apiAuthRequest<T>(url: string, body: string) {
    const authToken = getAuthToken();
        
    //find better solution
    let at = '';
    if (authToken !== undefined) {
        if (authToken.authToken !== undefined) {
            at = authToken.authToken;
        }
    }

    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + at
        },
        body: body
    });
        
    if (response.status == 200) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {

            const json = await response.json();
            return json as T;
        }

        return;
    }
    else {
        const text = await response.text();
        alert(text);
    }
}
