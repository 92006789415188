import React from 'react';
import { getAccessLevel } from '../services/AccessService';
import { TAccessLevel } from '../types/TAccessLevel';

export const BaseInternalPage = (props: any) => {
    const { children } = props;

    var accessLeves = getAccessLevel();

    let isLoggedIn = false;
    if (accessLeves.includes(TAccessLevel.ADMIN) || accessLeves.includes(TAccessLevel.USER)) {
        isLoggedIn = true;
    }

    if (isLoggedIn) {
    return(
        <div >
            {children}
        </div>)
    }
    else {
        return (   
        <div>
            <h1>No access</h1>
        </div>)
    };
}

export default BaseInternalPage;
