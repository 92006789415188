import { TUser } from "../types/TUser";
import { getAuthToken } from "./LocalStorageService";
import jwt_decode from "jwt-decode";
import { TUserClaims } from "../types/TUserClaims";
import { TAccessLevel } from "../types/TAccessLevel";
import { TRole } from "../types/TRole";

export function getAccessLevel(): TAccessLevel[] {
    const token = getAuthToken();

    if (token === undefined || token.authToken === undefined) {
        return [TAccessLevel.UNDEFINED]
    }

    var authJson = jwt_decode<TUserClaims>(token.authToken);

    const roles: TRole[] = JSON.parse(authJson.role);

    const accessLevels = roles.map(r => r.AccessLevel);

    if (accessLevels.length == 0) {
        return [TAccessLevel.UNDEFINED];
    }

    return accessLevels;
}
