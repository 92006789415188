import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { getAccessLevel } from '../services/AccessService';

import menuIcon from '../Images/menu.svg';
import { removeAuthToken } from '../services/LocalStorageService';
import { useState } from 'react';
import { TAccessLevel } from '../types/TAccessLevel';

const NavMenu = () => {
    const [menuStyle, setMenuStyle] = useState('menu-hidden');

    var accessLeves = getAccessLevel();

    let isLoggedIn = false;
    if (accessLeves.includes(TAccessLevel.ADMIN) || accessLeves.includes(TAccessLevel.USER) || accessLeves.includes(TAccessLevel.ADMIN)) {
        isLoggedIn = true;
    }

    const ProcessSignOff = async () => {
        removeAuthToken();
        window.location.href = "/"
    }

    const myFunction = () => {
        if (menuStyle === "menu") {
            setMenuStyle("menu-hidden");
        }            
        else {
            setMenuStyle("menu");
        }
    } 

    return (
        <header>

            <div className="menu-top">
                <NavLink tag={Link} to="/"><h1> BaseWeb.eu</h1></NavLink>
                <Link className="mobile-menu-hamburger" to='#' onClick={myFunction}>
                    <img className="nav-icon" src={menuIcon} />
                </Link>
            </div>
            <div className={menuStyle}>
            {isLoggedIn ?
                <NavLink tag={Link} to="/" onClick={ProcessSignOff} >Sign out </NavLink> :
                <NavLink tag={Link} to="/signin" >Sign in </NavLink>
                }
            </div>
            <div>
                {isLoggedIn ?
                    <ul className={menuStyle}>
                        <li>
                            <NavLink tag={Link} onClick={myFunction} to="/objectives">Tasks </NavLink>
                            <NavLink tag={Link} onClick={myFunction} to="/priorities">Priority </NavLink>
                            <NavLink tag={Link} onClick={myFunction} to="/categories">Category </NavLink>
                            <NavLink tag={Link} onClick={myFunction} to="/settings">Settings </NavLink>
                        </li>
                    </ul> :
                    <ul className={menuStyle}>                            
                    </ul>
                }
            </div>
        </header>
    );
}

export default NavMenu
