import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { sendResetPasswordLink } from '../../services/ApiRequestService';
import { TSendResetPasswordLinkRequest } from '../../types/TSendResetPasswordLinkRequest';

const PasswordRecoveryPage = () => {

    const [resetPasswordLinkRequest, setResetPasswordLinkRequest] = useState<TSendResetPasswordLinkRequest>({} as TSendResetPasswordLinkRequest);

    useEffect(() => {


        return () => {
            //alert('Will unmount');
        };
    }, []);

    const send = async () => {
        await sendResetPasswordLink(resetPasswordLinkRequest);

        alert('Odesláno');
    }

    return (<div>
        <h1>Reset password</h1>
        <p>Email: <input type="text" onChange={(e) => setResetPasswordLinkRequest({ ...resetPasswordLinkRequest, email: e.target.value })} /></p>        

        <Button type='button' onClick={send}>Reset</Button>
    </div>)
};

export default PasswordRecoveryPage;
