import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TCredentials } from '../../types/TCredentials';
import { signIn } from '../../services/ApiRequestService';
import { setAuthToken } from '../../services/LocalStorageService';
import './SignIn.css';
import { useNavigate  } from 'react-router';

const SignInPage = () => {
    const [userEmail, setUserEmail] = useState<string>('');
    const [userPassword, setUserPassword] = useState<string>('');
    const navigate = useNavigate();
    
    
    const ProcessSignIn = async () => {
        if (!userEmail || !userPassword) {
            alert('No email or password');
            return;
        }

        const requestBody: TCredentials = {
            email: userEmail,
            password: userPassword
        };

        const token = await signIn(requestBody);

        if (!token.successful) {
            alert("Login was not successful.");
        }
        else {
            setAuthToken(token);
            window.location.href = "/"
        }
    }

    return <div className="login">
        <div className="title"><h3>Login page</h3></div>
        <div><p>Email: <input onChange={e => setUserEmail(e.target.value)} value={userEmail} type="text" /></p></div>
        <div><p>Email:<input onChange={e => setUserPassword(e.target.value)} value={userPassword} type="password" /></p></div>
        <button onClick={ProcessSignIn}>Login</button>
        <Link to='/passwordrecovery'>
            <button type='button'>Reset password</button>
        </Link>
        {/*</form>*/}
    </div>
};

export default SignInPage;

