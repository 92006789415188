import * as React from 'react';

const Home = () => (
    <div>

        <h1>Test page</h1>
    </div>
);

export default Home;
