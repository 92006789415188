//date to string
export function convertToDateString(date: Date): string {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0');
    var yyyy = date.getFullYear();

    const dateSting = yyyy + '-' + mm + '-' + dd;

    return dateSting;
}

export function convertToTimeString(date: Date): string {
    const hoursString = date.getHours().toString();
    const minutesString = String(date.getMinutes()).padStart(2, '0');

    return hoursString + ":" + minutesString;
}

export function convertToIsoString(date: Date): string{
    return date.toISOString();
}

//string to date
export function convertFromDateString(dateString: string): Date {
    return new Date(dateString);
}

export function convertFromTimeString(timeString: string): Date {
    const isoString = "0001-01-01T" + timeString + ":00Z";
    return convertFromIsoString(isoString);
}

export function convertFromIsoString(isoString: string): Date {
    return new Date(isoString);
}





